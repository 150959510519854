<template>
  <div>
    <t-dropdown>
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          style="height: 35px; width: 155px"
          aria-label="Export As Dropdown Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <div
            class="flex items-center justify-between py-0 pl-4 font-medium border rounded-full shadow-sm text-14px text-oCharcoal focus:border-oCharcoal hover:border-oCharcoal focus:outline-none"
          >
            <div class="flex items-center relative">
              <svg
                class="w-6 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                />
              </svg>
              <span class="text-gray-700">
                Export As
              </span>
            </div>
            <div
              class="flex items-center justify-center m-0 bg-gray-100 rounded-r-full "
              style="height:33px; width:35px"
            >
              <svg
                v-if="isFileExporting"
                @click="$emit('cancel-export')"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
              >
                <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                />
              </svg>
              <svg
                v-else
                class="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
        </button>
      </template>

      <template #default="{ hide }">
        <div
          class="py-1 mt-px bg-white rounded-md shadow-md"
          v-if="!isFileExporting"
        >
          <t-dropdown-item
            @click="
              $emit('csv')
              hide()
            "
          >
            CSV
          </t-dropdown-item>

          <!-- <t-dropdown-item
            @click="
              $emit('excel')
              hide()
            "
          >
            EXCEL
          </t-dropdown-item> -->
          <t-dropdown-item
            v-if="isQrCodeExport"
            @click="
              $emit('download-qr')
              hide()
            "
          >
            Download QR Code
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </div>
</template>

<script>
import TDropdownItem from '@/components/dropdown/TDropdownItem'

export default {
  name: 'ExportAsDropdown',
  components: {
    TDropdownItem,
  },
  props: {
    isQrCodeExport: {
      type: Boolean,
      default: false,
    },
    isFileExporting: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
